import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    introBox,
    background,
    secretList,
    productBox
} from './spirit-page.module.scss';

import { IPage } from '../models/page.model';
import { IMainProduct } from '../models/main-product.model';
import { ISitePageContext } from '../models/site-page.model';
import {IQueryAllResult} from "../models/query-all-result.model";
import {ImageDataLike} from "../models/image-data-like.model";

import {getSecrets} from "../utils/get-secrets";
import {getNodes} from "../utils/get-nodes";

import MainLayout from '../layouts/main-layout';
import BackgroundRepeat from "../layouts/background-repeat";
import PageIntro from "../components/organisms/page-intro";
import SecretList from "../components/organisms/secret-list";
import ProductPreview from "../components/molecules/product-preview";


interface ISpiritPageProps {
    readonly data: {
        page: IPage | null;
        product: IMainProduct;
        allSecretImg: IQueryAllResult<ImageDataLike>;
    };
    readonly pageContext: ISitePageContext;
}

const SpiritPage: React.FC<ISpiritPageProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { page, product, allSecretImg } = data;
    const { site } = pageContext;
    const { introContent } = page || {};
    const secretImages = getNodes(allSecretImg);
    const secrets = getSecrets(secretImages, 'flavored.secret.item.title.spirit', t);

    return (
        <MainLayout page={page} className={layout} siteKey={site}>
            {product && (
                <BackgroundRepeat className={background}>
                    <div className={introBox}>
                        <PageIntro title={t("spirit.page.title")} subTitle={t("spirit.page.subTitle")}  description={introContent} />
                        <SecretList
                            secrets={secrets}
                            className={secretList}
                            threeElem
                            secretItemNumbers
                        />
                    </div>
                    {product && <ProductPreview recipe={product} className={productBox} additionalName={product.name}/>}
                </BackgroundRepeat>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }

        product: strapiMainProduct(
            locale: { eq: $language }
            type: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...mainProductFields
        }
        allSecretImg: allFile(
            filter: {
                relativePath: {
                    in: [
                       "spirit-drawing-01.png"
                        "spirit-drawing-02.png"
                        "spirit-drawing-03.png"
                     
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

export default SpiritPage;
